.gallery-img {
  height: 75vh;
  object-fit: cover;
  object-position: 50% 70%;
}
.gallery-carousel {
  width: 70%;
  border: 3px solid white;
  margin: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  .gallery-carousel {
    width: 100%;
    border: 1px solid white;
  }
}
@media (max-width: 468px) {
  .gallery-img {
    height: 45vh;
  }
}

.slider-arrow {
  font-size: 2rem;
}
.slider-arrow:active {
  color: red;
}

.slider-text-section {
  width: 40%;
  height: 60vh;
  background: #f8f8f8;
  position: relative;
}

.slider-image-section {
  width: 60%;
  object-fit: cover;
  height: 60vh;
}

.slider-text-section ul {
  font-size: 1vw;
  list-style: none;

  padding: 0;
}

#services h3 {
  font-size: 2vw;
  margin-bottom: 20px;
}
#services li {
  margin-bottom: 10px;
}
#services .arrows {
  position: absolute;
  bottom: 1.5rem;
}

@media only screen and (min-width: 2400px) {
  .slider-text-section ul {
    font-size: 5vw;
  }
}

@media (max-width: 768px) {
  .slider-text-section {
    width: 100%;
    max-height: 75vh;
  }
  .slider-image-section {
    width: 100%;
    height: 40vh;
  }
  #services .arrows {
    bottom: 0.5rem;
  }
  #services h3 {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  #services li {
    margin-bottom: 2px;
  }
  .slider-text-section ul {
    font-size: 0.65rem;
  }
}

.my-footer {
  background-color: black;
  padding: 40px;
  font-size: 14px;
  margin: 0px;
}

.my-footer a {
  color: lightgrey !important;
  max-width: 50%;
  display: block;
  margin: 0px;
  padding: 0px !important;
}
.my-footer p {
  margin: 0px;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;800&display=swap');

@import "./custom.scss";

    $myPrimary: #EC1A23;
    $mySecondary: white;

.my-app{
    max-width: 100vw;
    overflow: hidden;
    // background-color: #E9ECEF;
    background-color: white;
}
.my-page{
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
}

.cursor-pointer{
    cursor:pointer;
}

body{
    font-family: "montserrat", "sans-serif";
}
.active-link-color{
    color: $myPrimary !important;
}

.lang{
 //border: 1px solid $myPrimary;
color: $mySecondary;
}
.lang:active{
 //border: 1px solid $myPrimary;
color: $myPrimary;
}

 .lang:hover{
     font-size: 1rem;
 }
.vertical-center{
    display: flex;
    align-items: center;
    
}

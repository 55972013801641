.my-header {
  /* z-index: -1; */
  /* opacity: 0.6; */
  background: rgba(0, 0, 0, 0.5);
}

#navb a {
  margin-right: 1.7rem;
}



.slider-top-fixed {
  height: 100vh;
  overflow: hidden;
  position: relative;
  top: 0px;
  background: black;
}

.slider-img {
  opacity: 0.7;
  width: 100%;
  /* background-position: 250px; */
  object-fit: cover;
  object-position: 35% 65%;
  /* background-size: cover; */
  height: 100vh;
}

.landing-button a:hover {
  color: "yellow" !important;
}

@media (max-width: 768px) {
  .slider-top-fixed {
    height: 50vh;
  }
  .slider-img {
    height: 50vh;
  }
}

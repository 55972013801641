#contact h6 {
  font-size: 0.9rem;
  font-weight: 550;
  margin-bottom: 0;
}

#contact p {
  font-size: 0.8rem;
}

.contact-details {
  overflow: hidden;
  width: 100vw;
  padding: 2rem 4rem;
}

.socials {
  color: #000;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .contact-details {
    padding: 0;
  }
  .map {
    height: 220px;
  }
}

@media only screen and (min-width: 1500px) {
  #services .slider-image-section {
  }
}
@media only screen and (min-width: 2400px) {
  #services .slider-image-section {
  }
  p {
    font-size: 80px;
  }
}
.container-fluid {
  padding: 5vw !important;
}

@media (max-width: 768px) {
  #contact .container-fluid {
    padding: 0 !important;
  }
}
